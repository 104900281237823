import React, { Component } from "react";
import ContactUsSection from "../../../contactUsSection/contactUsSection";
import CtaButtons from "../../../ctaButtons/ctaButtons";
import ListComponent from "../../../listComponent/listComponent";
import ImageSlideshow from "../../../ImageSlideshow";
import "./aiAgentView.scss";

export default class AiAgentView extends Component {
  render() {
    return (
      <div className="page" id="dataEngineeringAndMlPage">
        <div className="page-section">
          <div className="page-container container hero-container">
            <div className="row align-items-center g-3">
              <div className="col-lg-12 col-md-12 text-center mb-4">
                <h1 className="mb-3">Introducing Jane</h1>
                <h2 className="mb-3">Data Assistant for every business user</h2>
                <p className="fs-5 mb-4">
                  Get answers of data using natural language questions
                </p>
                <CtaButtons center={true} />
              </div>
              <div className="col-lg-12  col-md-12 h-100 right-section">
                <div className="img-wrapper text-center">
                  <img
                    src="/images/agentFlow.svg"
                    width="90%"
                    alt="agent architecture"
                    className="mx-auto"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-section">
          <div className="page-container container">
            <div className="row">
              <div className="mb-5">
                <h2>Overview</h2>
                <p className="fs-5">
	          With Jane, business teams can effortlessly create a story/dashboard without any
	          knowledge of SQL. 
	          
	          Data analyst teams support and monitor responses and influence by constantly improving its knowledge base.
	          With additional custom knowledge, Jane becomes smarter and reliable.
                </p>
              </div>
              <div className="d-flex align-items-center justify-content-center">
                <img
                  src="/images/products/agent_header_image.svg"
                  width="80%"
                  alt="Xceed Data Agent"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="page-section">
          <div className="page-container container">
            <div className="row align-items-start g-5">
              <div className="col-xxl-6 col-lg-6">
                <h6 className="pre-heading-highlight fw-bold">
                    Intelligent AI Agents 
                </h6>
                <h3>
                  Data Agents for various roles and tasks
                </h3>
                <ListComponent
                  data={[
                    "Unified approach accelarates building agents for all data roles",
                    "Xceed Intelligence framework enables building, augmenting and customizing data agents for various tasks/processes",
	            "Jane, the business analyst agent provides conversational experience for getting answers and building dashboards",
	            "Coming soon, Liam the Data Engineer Agent and Mary, A Data Science Agent..."
                  ]}
                />
              </div>
              <div className="col-xxl-6 col-lg-6">
                <div className="img-wrapper text-center">
                  <img
                    src="/images/dataAgentList.svg"
                    width="100%"
                    alt="Data agents for all roles"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-section">
          <div className="page-container container ">
            <div className="row">
              <div className="col-xxl-6 col-lg-6">
                <div className="img-wrapper text-center">
                  <img
                    src="/images/agentConversationPinup.svg"
                    width="90%"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-xxl-6 col-lg-6">
                <h6 className="pre-heading-highlight fw-bold">
                  Experiencial and scalable approach
                </h6>
                <h3>Experiencial Overall approach</h3>
                <ListComponent
                  data={[
	            "Conversational experience pre-integrated with Xceed Dashboards ensures best of AI/BI functionality",
                    "Semantic index is automatically refreshed on a catalog/metadata udpate",
                    "In-build vector database and search",
	            "Data teams can influence agent performance and reliability by improving their knowledge base",
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="page-section">
          <div className="page-container container">
            <div className="row g-5">
              <div className="col-xxl-5 col-lg-6">
                <h6 className="pre-heading-highlight fw-bold">
                  Monitor Agent Performance in Real Time
                </h6>
                <h3>Monitor and Take Action</h3>
                <ListComponent
                  data={[
                    `Track overall performance of agents across threads/sessions`,
                    `Track consumption by users, task or sessions`,
		    "Drill down into specific failures and review the conversation",
		    "Take actions by influencing trusted examples to correct agents behavior on specific issue",
                  ]}
                />
              </div>
              <div className="col-xxl-7 col-lg-6">
                <div className="img-wrapper text-center">
		    <ImageSlideshow
		      images={[
			"/images/AgentMonitoringKPIDashboard.svg",
			"/images/AgentMonitoringDetails.svg",
		      ]}
		      timerLimit={3000}
		    />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-section">
          <div className="page-container container">
            <div className="row g-5">
              <div className="col-xxl-5 col-lg-6">
                <h6 className="pre-heading-highlight fw-bold">
                  COLLABORATE WITH OTHER ANALYSTS USERS
                </h6>
                <h3>Govern, Share and Deploy</h3>
                <ListComponent
                  data={[
                    `Share your data models across all the data teams who need access to your model instantly`,
		    "Deploy multiple instances of Jane by department, function or business ensuring scalability",
                  ]}
                />
              </div>
              <div className="col-xxl-7 col-lg-6">
                <div className="img-wrapper text-center">
                  <img
                    src="/images/dataPreparationVisual3.svg"
                    width="90%"
                    alt="cynepia govern share"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>



        <ContactUsSection />
      </div>
    );
  }
}
