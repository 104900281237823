import React from "react";
import ImageSlideshow from "../../../ImageSlideshow";

const DataAgentSection = () => {
  const data = [
    "Now business users can get answers to your business questions using natural language",
    "Create interpretable stories and dashboards right from within conversational interface",
    "Governed & Secure search ensures users can only discover the assets they have access to.",
    "Data Analyst can monitor agent KPIs and drill into details.",
    "Take Actions to improve reliability by adding trusted knowledge and queries right from the catalog"
  ];
  return (
    <div className="page-section" id="Text2SQLSection">
      <div className="page-container container rounded py-3">
        <div className="row align-items-start justify-content-between g-3 p-5">
          <div className="col-xxl-6 col-lg-6 ">
            <h3 className="">Jane, data analyst agent for business teams</h3>

            <div className="d-flex flex-column gap-3">
              {data.map((item, i) => {
                return (
                  <div key={i} className="border white px-3 py-3 rounded">
                    {item}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="col-xxl-5 col-lg-5">
            <ImageSlideshow
              images={[
                "/images/agentConversation.svg",
		"/images/AgentMonitoringKPIDashboard.svg",
		"/images/AgentMonitoringDetails.svg",
              ]}
              timerLimit={3000}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataAgentSection;
